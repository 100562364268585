<template>
  <ManagementPage title="Signed-URL">
    <div class="description">
      {{$t('teamSettings.signUrl.description')}}
    </div>
    <ManageSignedUrlSection />
    <UrlSigningKeyListSection />
  </ManagementPage>
</template>

<script>
import ManagementPage from '@/layouts/ManagementPage.vue';
import { UrlSigningKeyListSection } from '@/modules/urlSigningKey';
import ManageSignedUrlSection from '../components/organisms/manageSignedUrlSection/ManageSignedUrlSection.vue';

export default {
  components: {
    ManagementPage,
    ManageSignedUrlSection,
    UrlSigningKeyListSection,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.description {
  margin-bottom: $spacing-base * 0.75;
  font-size: $font-size-base * 1.25;
}

@media screen and (max-width: $max-layout-sm) {
  .description {
    font-size: $font-size-base;
  }
}

</style>
