<template>
  <svg
    class="copy-icon"
    :width="size"
    :height="size"
    viewBox="0 0 12 12">
    <!-- eslint-disable -->
    <path
      :fill="color"
      d="M10.3604 0.633301H3.65211C3.09637 0.633301 2.64586 1.08381 2.64586 1.63955V2.6458H1.63961C1.08387 2.6458 0.633362 3.09631 0.633362 3.65205V10.3604C0.633362 10.9161 1.08387 11.3666 1.63961 11.3666H8.34795C8.90369 11.3666 9.3542 10.9161 9.3542 10.3604V9.35413H10.3604C10.9162 9.35413 11.3667 8.90363 11.3667 8.34788V1.63955C11.3667 1.08381 10.9162 0.633301 10.3604 0.633301ZM8.22216 10.3604H1.76539C1.73203 10.3604 1.70004 10.3471 1.67645 10.3235C1.65286 10.3 1.63961 10.268 1.63961 10.2346V3.77783C1.63961 3.74447 1.65286 3.71248 1.67645 3.68889C1.70004 3.6653 1.73203 3.65205 1.76539 3.65205H2.64586V8.34788C2.64586 8.90363 3.09637 9.35413 3.65211 9.35413H8.34795V10.2346C8.34795 10.268 8.33469 10.3 8.3111 10.3235C8.28752 10.3471 8.25552 10.3604 8.22216 10.3604ZM10.2347 8.34788H3.77789C3.74453 8.34788 3.71254 8.33463 3.68895 8.31104C3.66536 8.28746 3.65211 8.25546 3.65211 8.2221V1.76533C3.65211 1.73197 3.66536 1.69998 3.68895 1.67639C3.71254 1.6528 3.74453 1.63955 3.77789 1.63955H10.2347C10.268 1.63955 10.3 1.6528 10.3236 1.67639C10.3472 1.69998 10.3604 1.73197 10.3604 1.76533V8.2221C10.3604 8.25546 10.3472 8.28746 10.3236 8.31104C10.3 8.33463 10.268 8.34788 10.2347 8.34788Z"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: () => '#777776',
    },
    size: {
      type: String,
      required: false,
      default: () => '12',
    },
  },
};
</script>
