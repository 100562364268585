import _ from 'lodash';
import type { Nullable } from '@/modules/shared';
import type { Video } from '../types/video.type';

export function getVideoURL(video: Video): string {
  if (_.get(video, 'primaryPlaybackUrl.hls')) {
    return _.get(video, 'primaryPlaybackUrl.hls[0].url', '');
  }

  return _.get(video, 'primaryPlaybackUrl.mp4Playlist[0].url', '');
}

export function getEmbeddedCode(video: Nullable<Video>, referrerpolicy?: string): string {
  const iframeAllow = 'autoplay *; encrypted-media *; fullscreen *; picture-in-picture *; screen-wake-lock *;';

  if (video?.embeddedUrl) {
    return `<iframe src="${video.embeddedUrl}" width="560" height="320" frameborder="0" allowfullscreen ${referrerpolicy ? `referrerpolicy="${referrerpolicy}"` : ''} allow="${iframeAllow}"></iframe>`;
  }

  return '';
}
