<template>
  <a :href="href" target="_blank" rel="noreferrer">
    {{ text }} <i class="fas fa-up-right-from-square"></i>
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
