import project from '@/modules/shared/store/project';
import store from '@/store';
import { RouteRecordRaw } from 'vue-router';
import { getQueryAsString } from '@/modules/shared/utils/query';
import createVideoUploadRoutes from '@/modules/videoUpload/router';
import { createVideoRoutes } from '@/modules/videoDetail';
import { createWebhookRoutes } from '@/modules/webhook/router';
import { createProjectDefaultUploadSettingRoutes } from '@/modules/projectDefaultUploadSetting';
import {
  createProjectVideoListRoutes,
  projectDeletedVideoList,
  projectVideoCaptionIssueList,
  projectVideoList,
} from '@/modules/projectVideoList';
import { createProjectDetailsRoutes } from '@/modules/projectDetails';
import { createProjectMemberRoutes } from '@/modules/projectMember';
import { createProjectSettingRoutes } from '@/modules/projectSetting';
import { createProjectListRoutes } from '@/modules/projectList';
import { createProjectCreateRoutes } from '@/modules/projectCreate';
import { createCollectionRoutes } from '@/modules/collection';
import { collectionList, createCollectionListRoutes } from '@/modules/collectionList';
import { createExportFileListRoutes } from '@/modules/exportFileList';
import createVideoImportRoutes from '@/modules/videoImport/router';

export function createProjectRoutes(): RouteRecordRaw[] {
  return [
    ...createProjectListRoutes(),
    ...createProjectCreateRoutes(),
    {
      name: 'allProjects',
      path: 'all-projects',
      component: () => import(/* webpackChunkName: "projectChrome" */ '@/modules/projectVideoList/layouts/ProjectVideoListChrome.vue'),
      beforeEnter: (to, from, next) => {
        const projectKey = getQueryAsString(to.params, 'projectKey');
        const teamName = getQueryAsString(to.params, 'teamName') ?? '';
        const isAllProject = to.path.includes('all-projects');

        if (projectKey) {
          localStorage.setItem('projectKey', projectKey);
          localStorage.setItem('teamName', teamName);
        } else if (isAllProject) {
          localStorage.setItem('projectKey', 'all');
          localStorage.setItem('teamName', teamName);
        }

        if (!store.hasModule('project')) {
          store.registerModule('project', project);
        }
        if (!store.hasModule('projectVideoList')) {
          store.registerModule('projectVideoList', projectVideoList);
        }
        if (!store.hasModule('collectionList')) {
          store.registerModule('collectionList', collectionList);
        }
        if (!store.hasModule('projectVideoCaptionIssueList')) {
          store.registerModule('projectVideoCaptionIssueList', projectVideoCaptionIssueList);
        }
        if (!store.hasModule('projectDeletedVideoList')) {
          store.registerModule('projectDeletedVideoList', projectDeletedVideoList);
        }

        next();
      },
      children: [
        {
          name: 'allProjectVideos',
          path: '',
          component: () => import(/* webpackChunkName: "SectionVideoList" */ '@/modules/projectVideoList/pages/ProjectVideos.vue'),
        },
        {
          name: 'allProjectVideoIssues',
          path: 'video-issues',
          component: () => import(/* webpackChunkName: "SectionVideoList" */ '@/modules/projectVideoList/pages/ProjectVideoBadWordList.vue'),
        },
        {
          name: 'allRecentlyDeleted',
          path: 'recently-deleted',
          component: () => import(/* webpackChunkName: "recentlyDeleted" */ '@/modules/projectVideoList/pages/ProjectRecentlyDeletedVideo.vue'),
        },
      ],
    },
    ...createProjectVideoListRoutes(),
    ...createCollectionListRoutes(),
    ...createVideoUploadRoutes(),
    ...createExportFileListRoutes(),
    ...createVideoImportRoutes(),
    {
      path: 'projects/:projectKey/manage',
      component: () => import(/* webpackChunkName: "ProjectManageChrome" */ '@/layouts/ProjectManageChrome.vue'),
      redirect: 'projects/:projectKey/manage/general',
      children: [
        ...createProjectDetailsRoutes(),
        ...createProjectMemberRoutes(),
        ...createProjectDefaultUploadSettingRoutes(),
        ...createProjectSettingRoutes(),
        ...createWebhookRoutes(),
      ],
    },
    ...createCollectionRoutes(),
    ...createVideoRoutes(),
  ];
}
