<template>
  <div class="codeblock-container">
    <prism-editor
      v-bind:id="id"
      :modelValue="code"
      :highlight="highlighter"
      :readonly="readOnly"
      :lineNumber="true"
      class="code-editor"
      :class="getCustomClass()"  />
    <slot></slot>
    <div
      class="copy-button-container"
      v-if="isShowCopyButton"
      @click="onCopyCode"
    >
      <Button buttonStyle="text-secondary" size="large">
        <template v-slot:icon-prefix>
          <i class="far fa-check" v-if="isCopied" />
          <CopyIcon v-else />
        </template>
      </Button>
    </div>
  </div>
</template>

<script>
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import CopyIcon from '@/modules/shared/components/atoms/copyIcon/CopyIcon.vue';

export default {
  components: {
    Button,
    CopyIcon,
  },
  props: {
    code: {
      default: '',
    },
    language: {
      type: String,
      default: 'js',
    },
    id: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: true,
    },
    enabledFixHeight: {
      type: Boolean,
      default: false,
    },
    isShowCopyButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCopied: false,
    };
  },
  methods: {
    highlighter(code) {
      if (this.language === 'html') {
        return highlight(code, languages.markup, this.language);
      }
      if (this.language === 'json') {
        return highlight(code, languages.json, this.language);
      }
      return highlight(code, languages.js, this.language);
    },
    getCustomClass() {
      if (this.enabledFixHeight) {
        return 'height-350';
      }
      return '';
    },
    copyValue(value) {
      const input = document.createElement('input');
      input.value = value;
      input.style.position = 'fixed';
      input.style.top = 0;
      input.style.opacity = 0;
      document.body.appendChild(input);

      input.focus();
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
    },
    onCopyCode() {
      this.copyValue(this.code);
      this.isCopied = true;
      const timer = setTimeout(() => {
        this.isCopied = false;
        clearTimeout(timer);
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/themes/helpers.scss';
@import '~vue-prism-editor/dist/prismeditor.min.css';

pre {
  color: #f08d49; // Prevent pre from disappearing
}

.prism-editor-wrapper .prism-editor__textarea, .prism-editor-wrapper .prism-editor__editor {
  padding: 16px;
  min-height: 100px; // This prevent layout shifting on switching tabs.
}

.prism-editor__textarea:focus {
  outline: none;
}

.codeblock-container {
  position: relative;
  max-width: 100%;
  font-size: 14px;
  line-height: 1.5;
  padding: $spacing-base;
  background: #2d2d2d;
  color: #ccc;
  height: 100%;
  border-radius: 5px;
  display: flex;
  padding-right: 48px;

  :deep(.btn-copy) {
    height: fit-content;
  }
}

.height-350 {
  .prism-editor__code, pre[class*="language-"] {
    @extend .scrollbar;
    max-height: 350px;
  }
}

:deep(textarea) {
  display: none !important;
}

.copy-button-container{
  position: absolute;
  right: $spacing-8;
  top: $spacing-8;
}

</style>
